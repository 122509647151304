<template>
  <div class="cole">
    <div class="card shadow-sm btn" @click="productView(Product)">
      <img
        :src="Product.thump"
        alt="img"
        class="bd-placeholder-img card-img-top"
        width="100%"
        height="225"
      />

      <div class="card-body">
        <p class="card-text crt">
          {{ Product.project_name }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductBox",

  data() {
    return {
      pr_img_1: "",
    };
  },
  props: {
    Product: Object,
    slug: Object,
  },
  methods: {
    productView(data) {
      this.$router.push({
        name: "Productview",
        query: { product_slug: data.pr_slug, category_slug: data.cr_slug },
      });
    },
  },
};
</script>
<style scoped>
.btn {
  border-radius: 0.5;
  font-size: 0.5rem;
  padding: 2px;
}

.crt {
  font-size: 1.5em;
}
.cole {
  margin: 10px;
  width: 400px;
}
</style>
