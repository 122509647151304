<script>
import axios from "axios";

export default {
  name: "RollCkeck",
  data() {
    return {
      errors: [],
    };
  },
  mounted() {
    this.rollcheck();
  },
  methods: {
    async rollcheck() {
      await axios
        .get("users/api/user")
        .then((response) => {
          localStorage.setItem("username", response.data.email);
          localStorage.setItem("roll", response.data.select_roll);
          const roll = response.data.select_roll;
          this.$store.commit("setRoll", roll);
          localStorage.setItem("userId", response.data.id);

          if (localStorage.getItem("roll") == 2) {
            const topath = this.$route.query.to || "/dashboard";
            this.$router.push(topath);
          }
          if (response.data.select_roll == 1) {
            this.$store.commit("setIsLoading", true);
            axios.get("api/freelancer/profile/").then((res) => {
              console.log(res.data);
              localStorage.setItem("premium", res.data.is_premium);
            });
            const topath = this.$route.query.to || "/freedashboard";
            this.$router.push(topath);
          }
        })
        .catch((error) => {
          if (error.responce) {
            for (const property in error.responce.data) {
              this.errors.push(`${property}:${error.responce.data[property]}`);
            }
          } else if (error.message) {
            this.errors.push("Something went wrong, please tryagain");
          }
        })
        .finally(() => {
          this.$store.commit("setIsLoading", false);
        });
    },
  },
};
</script>
