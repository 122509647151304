import { createApp } from "vue";
import Meta from "vue-meta";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import GlobalComponents from "./includes/_globals";

/* import the fontawesome core */

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "./registerServiceWorker";

// axios.defaults.baseURL = "https://www.wecoxer.com";
axios.defaults.baseURL = "http://127.0.0.1:8000";
createApp(App)
  .use(store)
  .use(router, axios, Meta)
  // .use(GlobalComponents)
  .mount("#app");
