import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import LogIn from "../views/LogIn.vue";
import SignUp from "../views/SignUp.vue";
import Alpha from "../views/Alpha.vue";
import Beta from "../views/Beta.vue";
import RollCheck from "../components/RollCheck.vue";
import store from "../store";
import MarketPlace from "../views/MarketPlace.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/sitemap",
    name: "sitemap",
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../static/site_map.vue"),
  },
  {
    path: "/login",
    name: "LogIn",
    component: LogIn,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/roll",
    name: "RollCheck",
    component: RollCheck,
  },
  {
    path: "/marketplace",
    name: "MarketPlace",
    component: MarketPlace,
  },
  {
    path: "/termsandconditions",
    name: "Termsandcondition",
    component: () =>
      import(
        /* webpackChunkName: "Profile" */ "../views/Termsandcondition.vue"
      ),
  },
  {
    path: "/privacypolicy",
    name: "Privacypolicy",
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/Privacypolicy.vue"),
  },
  {
    path: "/copyrightinformation",
    name: "Copyright",
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/Copyright.vue"),
  },
  {
    path: "/workplace",
    name: "workplace",
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/Workplace.vue"),
  },
  {
    path: "/elements",
    name: "element",
    meta: {
      requireLogin: false,
    },
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/StockImages.vue"),
  },

  {
    path: "/category",
    name: "Categorywisemarket",
    meta: {
      requireLogin: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "Profile" */ "../views/Categorywisemarket.vue"
      ),
  },
  {
    path: "/element-category",
    name: "Categoryvisestock",
    meta: {
      requireLogin: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "Profile" */ "../views/Categoryvisestock.vue"
      ),
  },
  {
    path: "/element-view",
    name: "StockImageView",
    meta: {
      requireLogin: false,
    },
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/StockImageView.vue"),
  },
  {
    path: "/product",
    name: "Productview",
    meta: {
      requireLogin: false,
    },
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/Productview.vue"),
  },

  {
    path: "/freedashboard",
    name: "Beta",
    component: Beta,
    meta: {
      requireLogin: true,
    },
    children: [
      {
        path: "",
        name: "freedashboard",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/DashboardF.vue"),
      },
      {
        path: "/freeprofile",
        name: "fprofile",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Freeprofile.vue"),
      },
      {
        path: "/hiredjobs",
        name: "hjobs",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Hired.vue"),
      },

      {
        path: "/freecontract",
        name: "fcnt",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Contract.vue"),
      },
      {
        path: "/freemessage",
        name: "fmsg",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Chat.vue"),
      },
      {
        path: "/freeinvoice",
        name: "finvoice",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Invoice.vue"),
      },
      {
        path: "/freemonitor",
        name: "fmtr",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Monitor.vue"),
      },
      {
        path: "/allcontracts",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/AllContracts.vue"),
      },
      {
        path: "/allinvoices",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/AllInvoice.vue"),
      },
      {
        path: "/allmonitors",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/AllMonitor.vue"),
      },

      {
        path: "/createproject",
        name: "crtpjt",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "../views/CreateProject.vue"
          ),
      },
      {
        path: "/myproject",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Myprojects.vue"),
      },
      {
        path: "/myorders",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Myorders.vue"),
      },
      {
        path: "/editproject",
        name: "Editproject",

        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Editproject.vue"),
      },
      {
        path: "/stockupload",
        name: "StockUpload",

        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Stockupload.vue"),
      },
      {
        path: "/myuploads",
        name: "Myuploads",

        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Myuploads.vue"),
      },
      {
        path: "/freemessenger",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Messenger.vue"),
      },
    ],
  },

  {
    path: "/dashboard",
    name: "Alpha",
    component: Alpha,
    meta: {
      requireLogin: true,
    },
    children: [
      {
        path: "",
        name: "employerdashboard",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/DashboardE.vue"),
      },
      {
        path: "/postjob",
        name: "postjob",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Postjob.vue"),
      },
      {
        path: "/profile",
        name: "eprofile",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Profile.vue"),
      },
      {
        path: "/postedjob/edit",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Postjobedit.vue"),
      },
      {
        path: "/proposal",
        name: "proposal",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Proposal.vue"),
      },
      {
        path: "/hired",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Hired.vue"),
      },
      {
        path: "/contract",
        name: "ecnt",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Contract.vue"),
      },
      {
        path: "/invoice",
        name: "einvoice",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Invoice.vue"),
      },
      {
        path: "/monitor",
        name: "emtr",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Monitor.vue"),
      },
      {
        path: "/message",
        name: "emsg",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Chat.vue"),
      },
      {
        path: "/allcontract",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/AllContracts.vue"),
      },
      {
        path: "/allinvoice",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/AllInvoice.vue"),
      },
      {
        path: "/allmonitor",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/AllMonitor.vue"),
      },

      {
        path: "/marketplace-order",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Myorders.vue"),
      },
      {
        path: "/messenger",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Messenger.vue"),
      },
    ],
  },
  {
    path: "/activate/:uid/:token/",
    name: "Activate",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/Activate.vue"),
  },

  {
    path: "/reset/:uid/:token/",
    name: "PasswordResetConfirm",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/PasswordResetConfirm.vue"
      ),
  },
  {
    path: "/password_reset",
    name: "PasswordReset",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PasswordReset.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // linkActiveClass: "active",
});
router.beforeEach((to, from, next) => {
  // if (router.currentRoute.meta.requiresAuth === true) {
  //   next({ name: "LogIn", query: { to: to.path } });
  // }
  if (
    to.matched.some((record) => record.meta.requireLogin) &&
    !store.state.isAuthenticated
  ) {
    next({ name: "LogIn", query: { to: to.path } });
  } else {
    next();
  }
});

export default router;
