<template>
  <footer>
    <div class="footer-top">
      <div class="right">
        <ul>
          <li><strong class="heading">What's new</strong></li>
          <li>
            <router-link class="rl" :to="{ name: 'Beta' }">
              Freelance
            </router-link>
          </li>
          <li>
            <router-link class="rl" :to="{ name: 'Alpha' }"> Hire </router-link>
          </li>
          <li>
            <router-link class="rl" :to="{ name: 'MarketPlace' }">
              Marketplace
            </router-link>
          </li>
          <li>
            <router-link class="rl" :to="{ name: 'element' }">
              Element's
            </router-link>
          </li>
          <li>
            <router-link class="rl" :to="{ name: 'workplace' }">
              Artboard
            </router-link>
          </li>
        </ul>
      </div>
      <div class="right">
        <ul>
          <li><strong class="heading">Company</strong></li>
          <li>
            <router-link class="rl" :to="{ name: 'Privacypolicy' }"
              >Privaacy Policy</router-link
            >
          </li>
          <li>
            <router-link class="rl" :to="{ name: 'Termsandcondition' }"
              >Terms & contition</router-link
            >
          </li>
          <li>
            <router-link class="rl" :to="{ name: 'Copyright' }"
              >LicenseAgreement</router-link
            >
          </li>
          <li>
            <router-link class="rl" :to="{ name: 'Copyright' }"
              >CopyrightInformation</router-link
            >
          </li>
        </ul>
      </div>
      <div class="right">
        <ul>
          <li><strong class="heading">Contact</strong></li>
          <li href="mailto:info@wecoxer.com">info@wecoxer.com</li>
          <li>+ 919495161062</li>
          <li>LicenseAgreement</li>
          <li>CopyrightInformation</li>
        </ul>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="footer-btm-lgt">
        <i class="fa fa-globe" aria-hidden="true"></i>English(India)
      </div>
      <div class="footer-btm-rgt">
        <li>&copy;2023 Wecoxer</li>
        <li>Terms & contition</li>
        <li>LicenseAgreement</li>
        <li>CopyrightInformation</li>
      </div>
      <div class="footer-btm-mb"><li>&copy;2023 Wecoxer</li></div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "FooterView",
};
</script>
<style Scoped>
.heading {
  /* font-weight: bolder; */
  font-size: 16px !important;
}
.footer-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 2%;
  background-color: #f2f2f2;
  color: #616161;
}
.footer-top ul {
  text-align: left;
}
.footer-top ul li {
  list-style: none;
  color: #616161;
  font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-size: 13px !important;
  padding: 0.5em;
}
.footer-btm-lgt {
  display: flex;
}
.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #f2f2f2;
  height: 50px;
  font-size: 13px !important;
  border-top: 1px solid black;
}
.footer-btm-rgt {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  height: 50px;
  font-size: 13px !important;
}
.footer-btm-rgt li {
  list-style: none;
  padding: 0 15px;
  font-size: 13px !important;
}
.rl {
  text-decoration: none;
  color: inherit;
  position: relative;
}
.fa-globe {
  font-size: 0.8rem;
  padding-right: 5px;
}
.footer-btm-mb {
  display: none;
}
.footer-btm-mb li {
  list-style: none;
}
@media (width<1000px) {
  .footer-top {
    flex-direction: column;
  }
  .footer-btm-lgt {
    display: none;
  }
  .footer-btm-rgt {
    display: none;
  }
  .footer-btm-mb {
    display: flex;
  }
  .right {
    width: 100vw;
  }
}
</style>
