<template>
  <FormView>
    <form @submit.prevent="submitForm" class="p-4">
      <div class="form-outline mb-4">
        <input
          type="email"
          class="form-control"
          id="username"
          placeholder="email"
          v-model="email"
          @keyup="validateInput(email)"
          required
        />
      </div>
      <div class="form-outline mb-4">
        <input
          type="text"
          placeholder="First Name"
          id="input"
          class="form-control form-control-lg"
          v-model="first_name"
          required
        />
      </div>
      <div class="form-outline mb-4">
        <input
          type="text"
          placeholder="Last Name"
          id="input"
          class="form-control form-control-lg"
          v-model="last_name"
          required
        />
      </div>
      <div class="form-outline mb-4">
        <input
          type="text"
          placeholder="username"
          id="input"
          class="form-control form-control-lg"
          v-model="username"
          required
        />
      </div>
      <div class="form-outline mb-4">
        <select
          class="form-select"
          aria-label="Default select example"
          id="input"
          v-model="select_roll"
          required
        >
          <option selected value="2">Employer</option>
          <option value="1">Freelancer</option>
        </select>
      </div>
      <!-- Password input -->
      <div class="form-outline mb-4">
        <input
          type="password"
          placeholder="password"
          autocomplete="new-password"
          id="input"
          class="form-control form-control-lg"
          v-model="password"
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          title="Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters"
          minlength="8"
          required
        />
      </div>
      <div class="form-outline mb-4">
        <input
          type="password"
          placeholder="password"
          autocomplete="new-password"
          id="input"
          class="form-control form-control-lg"
          v-model="password2"
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          title="Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters"
          minlength="8"
          required
        />
      </div>

      <div class="d-flex justify-content-around align-items-center mb-4">
        <!-- Checkbox -->

        <a href="#!">Forgot password?</a>
      </div>
      <div class="notification is-danger text-danger" v-if="errors.length">
        <p v-for="error in errors" :key="error">{{ error }}</p>
      </div>
      <!-- Submit button -->
      <button type="submit" id="testbutton">SignIn</button>
    </form>
  </FormView>
  <div
    class="alert snackbar show alert-dismissible"
    role="alert"
    v-if="msg !== ''"
  >
    <div>
      {{ msg }}
      <div v-for="item in data" :key="item.msg">
        {{ item }}
      </div>
    </div>
    <div
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
    ></div>
  </div>
</template>

<script>
import axios from "axios";
import FormView from "@/components/Form.vue";
export default {
  name: "SignUp",
  components: {
    FormView,
  },
  data() {
    return {
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      select_roll: "",
      password: "",
      password2: "",

      msg: "",
      errors: [],
    };
  },
  mounted() {
    document.title = "Sign Up| Wecoxer";
  },
  methods: {
    validateInput(value) {
      return (this.errors[0] =
        value === "" ? "The Input field is required" : "");
    },
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      this.isSuccess == false;
      this.errors = [];
      if (this.username === "") {
        this.errors.push("The username is missing");
      }
      if (this.password === "") {
        this.errors.push("The password is too short");
      }
      if (this.password !== this.password2) {
        this.errors.push("The password mismatch");
      }
      if (!this.errors.length) {
        const formData = {
          username: this.username,
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          select_roll: this.select_roll,
          password: this.password,
        };
        await axios
          .post("/api/signup/", formData)
          .then((responce) => {
            this.msg = responce.data;
            this.msg = responce.data.message;
            this.username = "";
            this.first_name = "";
            this.last_name = "";
            this.email = "";
            this.select_roll = "";
            this.password = "";
            this.password2 = "";
          })
          .catch((error) => {
            if (error.responce) {
              for (const property in error.responce.data) {
                this.errors.push(
                  `${property}:${error.responce.data[property]}`
                );
              }
              // consol.log(JSON.stringify(error.response.data))
            } else if (error.message) {
              this.errors.push("usernaem or email already exist");
              this.errors.push(error.message);
            }
          })
          .finally(() => {
            this.$store.commit("setIsLoading", false);
          });
      }
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

<style scoped>
#testbutton {
  display: inline-block;
  font-weight: 400;
  border: 0.125rem solid transparent;
  position: relative;
  border-radius: 0.125rem;
  background-color: inherit;
  color: inherit;
  text-decoration: none;
  padding: 0.25rem 0.75rem;
  font-size: inherit;
  line-height: 1;
  cursor: pointer;
  background-color: #0067b8;
  color: #fff !important;
}
.snackbar {
  visibility: hidden;
  min-width: 350px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: rgb(9, 176, 37); /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 6; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 300px; /* 30px from the bottom */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */

.snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 300px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
</style>
