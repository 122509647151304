<template>
  <body>
    <Navbar></Navbar>
    <main>
      <section>
        <div class="fixed-top p-5" v-if="searchBoolean">
          <div class="container-fluid text-center">
            <form class="form-outline" @submit.prevent="search">
              <div class="input-group mb-3 pt-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search.."
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                  v-model="query"
                />
                <button class="btn search" type="submit" id="button-addon2">
                  <i class="fa fa-arrow-right"></i>
                </button>
              </div>
            </form>
            <a @click="reset" class="btn search" v-if="searchBoolean">cancel</a>
          </div>
        </div>
      </section>
      <section class="hero" v-if="!searchBoolean">
        <div class="container-fluid text-center col-xs-12 col-sm-12 col-md-8">
          <form class="form-outline" @submit.prevent="search">
            <div class="input-group mb-3 pt-3">
              <input
                type="text"
                class="form-control"
                placeholder="Search.."
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                v-model="query"
              />
              <button class="btn search" type="submit" id="button-addon2">
                <i class="fa fa-arrow-right"></i>
              </button>
            </div>
          </form>
        </div>
      </section>

      <section v-if="!searchBoolean">
        <div class="content">
          <h5>Trending Categories</h5>

          <p class="text-black-50">
            WecoXer Helps you to find right deal for your Business ..
          </p>
        </div>
        <div class="container">
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3">
            <div
              class="col"
              v-for="category in latestcategorys"
              :key="category.id"
            >
              <div>
                <div class="" @click="nextpage(category)">
                  <div class="container">
                    <h6 class="carden">
                      <b>{{ category.category }}</b>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <figure>
        <div class="album py-5 bg-light mt-5" v-if="searchBoolean">
          <div class="container">
            <div class="prd-view">
              <ProductBox
                v-for="Product in paginatedData"
                :key="Product.id"
                :Product="Product"
                :slug="slug"
              />
            </div>
          </div>
        </div>
        <div class="album py-5 bg-light" v-else>
          <div class="container">
            <div class="prd-view">
              <ProductBox
                v-for="Product in latestprojects"
                :key="Product.id"
                :Product="Product"
                :slug="slug"
              />
            </div>
          </div>
        </div>
      </figure>
      <ul class="pagination p-3" v-if="data_d.length > 10 || currentPage > 1">
        <li class="pagination-item" title="Первая страница">
          <button
            type="button"
            @click="onClickFirstPage"
            :disabled="isInFirstPage"
          >
            <i class="fa fa-chevron-left"></i>
            <i class="fa fa-chevron-left"></i>
          </button>
        </li>

        <li class="pagination-item" title="Предыдущая страница">
          <button
            type="button"
            @click="onClickPreviousPage"
            :disabled="isInFirstPage"
          >
            <i class="fa fa-chevron-left"></i>
          </button>
        </li>

        <li class="pagination-item" v-for="(page, index) in pages" :key="index">
          <button
            type="button"
            @click="onClickPage(page.number)"
            :disabled="page.isDisabled"
            :class="{ active: isPageActive(page.number) }"
          >
            {{ page.number }}
          </button>
        </li>

        <li class="pagination-item" title="Следующая страница">
          <button
            type="button"
            @click="onClickNextPage"
            :disabled="isInLastPage"
          >
            <i class="fa fa-chevron-right"></i>
          </button>
        </li>
        <li class="pagination-item" title="Последняя страница">
          <button
            type="button"
            @click="onClickLastPage"
            :disabled="isInLastPage"
          >
            <i class="fa fa-chevron-right"></i>
            <i class="fa fa-chevron-right"></i>
          </button>
        </li>
      </ul>
    </main>
    <FooterView></FooterView>
  </body>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Navbar from "../components/Navbar.vue";
import ProductBox from "../components/ProductBox.vue";
import FooterView from "../components/Footer.vue";
import axios from "axios";
export default {
  name: "StockImages",
  data() {
    return {
      alltags: [
        {
          type: "name",
          name: "keywords",
          type2: "content",
          content:
            "Marketplace,find talent,find createive people,digital projects,social media marketing ",
        },
        {
          type: "name",
          name: "description",
          type2: "content",
          content:
            "Ready to work for you project. ✓ Wordpress ✓ social media ✓ Graphic design ✓ logo designing ✓ All that you need for your Creative Projects",
        },
        {
          type: "property",
          name: "og:title",
          type2: "content",
          content:
            "Digital marketplace. ✓ Wordpress ✓ social media ✓ Graphic design ✓ logo designing ✓ Webdevelopment ✓ All that you need for your Creative Projects",
        },
        {
          type: "property",
          name: "og:description",
          type2: "content",
          content:
            "Discover millions talent| Best Freelancing website for beginners|Find thousends of projuct in our market place",
        },
        {
          type: "property",
          name: "og:type",
          type2: "content",
          content: "article",
        },
        {
          type: "property",
          name: "og:url",
          type2: "content",
          content: "https://wecoxer.com",
        },
        {
          type: "property",
          name: "og:image",
          type2: "href",
          content: "https://wecoxer.com/img/wecoxerr.dafe9b6c.png",
        },
        {
          type: "property",
          name: "og:image:alt",
          type2: "content",
          content: "Discover millions of talent|marketplace",
        },
        {
          type: "property",
          name: "og:site_name",
          type2: "content",
          content: "Wecoxer",
        },
        {
          type: "property",
          name: "fb:admins",
          type2: "content",
          content: "110306301803952",
        },
        {
          type: "name",
          name: "twitter:card",
          type2: "content",
          content: "summary_large_image",
        },
        {
          type: "name",
          name: "twitter:site",
          type2: "content",
          content: "@wecoxer",
        },
        {
          type: "name",
          name: "twitter:creator",
          type2: "content",
          content: "@BhattPradyumnan",
        },
        {
          type: "name",
          name: "twitter:title",
          type2: "content",
          content: "Wecoxer | Digital Marketplace for everyone",
        },
        {
          type: "name",
          name: "twitter:image:src",
          type2: "href",
          content: "https://wecoxer.com/img/wecoxerr.dafe9b6c.png",
        },
        {
          type: "name",
          name: "twitter:url",
          type2: "content",
          content: "https://wecoxer.com",
        },
        {
          type: "name",
          name: "twitter:description",
          type2: "content",
          content: "Find talent online| find project | ",
        },
      ],
      slug: "",
      data_d: "",

      query: "",
      searchBoolean: false,
      typ: 1,
      maxVisibleButtons: 3,
      totalPages: {
        type: Number,
        required: true,
      },

      total: {
        type: Number,
        required: true,
      },
      perPage: 10,
      currentPage: {
        type: Number,
        required: true,
      },
    };
  },

  components: { Navbar, FooterView, ProductBox },
  created() {
    if (this.$store.getters.latestcategorys.length < 1) {
      (this.slug = this.fetchlatestproject()), this.fetchlatestcategory();
    }
    document.title = "Marketplace|WecoXer";
    this.metaTag();
  },
  computed: {
    ...mapGetters(["latestprojects"]),
    ...mapGetters(["latestcategorys"]),

    paginatedData() {
      let start = (this.currentPage - 1) * this.perPage,
        end = start + this.perPage;
      return this.data_d.slice(start, end);
    },
    startPage() {
      if (this.currentPage === 1) return 1;
      if (this.currentPage === this.totalPages)
        return (
          this.totalPages -
          this.maxVisibleButtons +
          (this.maxVisibleButtons - 1)
        );
      return this.currentPage - 1;
    },
    endPage() {
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      );
    },
    pages() {
      let range = [];
      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          number: i,
          isDisabled: i === this.currentPage,
        });
      }
      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  unmounted() {
    this.removeTag();
  },
  methods: {
    ...mapActions(["fetchlatestproject"]),
    ...mapActions(["fetchlatestcategory"]),

    nextpage(category) {
      this.$router.push({
        name: "Categorywisemarket",
        query: { slug: category.slug, id: category.id },
      });
    },
    reset() {
      this.searchBoolean = false;
    },
    onClickFirstPage() {
      this.currentPage = 1;
    },
    onClickPreviousPage() {
      this.currentPage = this.currentPage - 1;
    },
    onClickPage(page) {
      this.currentPage = page;
    },
    onClickNextPage() {
      this.currentPage = this.currentPage + 1;
    },
    onClickLastPage() {
      this.currentPage = this.totalPages;
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
    async search() {
      this.searchBoolean = true;
      this.$store.commit("setIsLoading", true);

      await axios
        .post("/api/marketplace/search/", { query: this.query, type: this.typ })
        .then((response) => {
          this.data_d = response.data;

          this.totalPages = Math.ceil(this.data_d.length / 10);
          this.total = this.data_d.length;

          this.currentPage = 1;
        })
        .catch((error) => {
          if (error.responce) {
            for (const property in error.responce.data) {
              this.errors.push(`${property}:${error.responce.data[property]}`);
            }
          } else if (error.message) {
            this.errors.push("Something went wrong, please tryagain");
          }
        })
        .finally(() => {
          this.$store.commit("setIsLoading", false);
        });
    },
    metaTag() {
      for (let i = 0; i < this.alltags.length; i++) {
        let meta = document.createElement("meta");
        meta.setAttribute(this.alltags[i].type, this.alltags[i].name);
        meta.setAttribute(this.alltags[i].type2, this.alltags[i].content);
        meta.setAttribute("class", "changable");
        document.head.appendChild(meta);
      }
    },
    async removeTag() {
      document.querySelectorAll(".changable").forEach((element) => {
        element.remove();
      });
    },
  },
};
</script>
<style scoped>
.prd-view {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}
@media only screen and (min-width: 768px) {
  .hero {
    background-image: url(../assets/bg-mk.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    color: rgb(31, 17, 17);
    height: 95vh;

    display: flex;
    align-items: center;
    overflow: hidden;
  }
}
.container-fluid {
  padding: 0;
}

.content {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;
}
@media only screen and (max-width: 768px) {
  .hero {
    background-image: url(../assets/bg-mk.webp);
    background-size: cover;
    background-repeat: no-repeat;
    color: rgb(31, 17, 17);
    height: 100vh;

    display: flex;
    align-items: center;
    overflow: hidden;
  }
}

.hero form {
  position: relative;
  z-index: 1;
  font-size: 4.5rem;
  margin: 0 0 10px;
  line-height: 1;
}

.hero p {
  position: relative;
  z-index: 1;
  font-size: 1.1rem;
  color: grey;
  line-height: 1.4;
}
.carden {
  font-size: 16px;
  cursor: pointer;
}

.search {
  color: #000000;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #d6daeb;
}
#inputGroupSelect01 {
  max-width: 150px;
}
.pagination {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: auto 0 0 0;
  list-style-type: none;
}
.pagination-item button {
  margin: 0 !important;
  padding: 0.25rem 0.5rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 0.25rem;
  background: none;
}
.pagination-item button:hover {
  cursor: pointer;
  background-color: silver;
  color: grey;
}
.pagination-item button[disabled="disabled"] {
  color: silver;
  cursor: default;
}
.pagination-item button[disabled="disabled"]:hover {
  cursor: default;
  background-color: transparent;
}
.pagination-item button:active {
  color: grey;
}
</style>
