<template>
  <nav class="nav">
    <div class="left">
      <router-link class="rl" :to="{ name: 'home' }">
        <text class="logo" alt="logo">Wecoxer</text>
      </router-link>
    </div>
    <div class="left--second-col">
      <ul>
        <li class="drp-lnk">
          <router-link class="rl" :to="{ name: 'Beta' }">
            Freelance
          </router-link>
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
          <div class="nav-dropdown">
            <div class="nav-col">
              <router-link :to="{ name: 'Beta' }">Search Work</router-link>
              <router-link :to="{ name: 'fprofile' }">Profile</router-link>
              <router-link :to="{ name: 'hjobs' }">Hired List</router-link>
              <router-link :to="{ name: 'fcnt' }">Create Contract</router-link>
            </div>
            <div class="nav-col">
              <router-link :to="{ name: 'finvoice' }"
                >Create Invoice</router-link
              >
              <router-link :to="{ name: 'fmsg' }">Messenger</router-link>
              <router-link :to="{ name: 'fmtr' }">Work Monitoring</router-link>
              <router-link :to="{ name: 'crtpjt' }"
                >Sell Your Project</router-link
              >
              <router-link :to="{ name: 'fmtr' }"
                >Sell your creative elements</router-link
              >
              <router-link :to="{ name: 'fmtr' }"></router-link>
            </div>
          </div>
        </li>
        <li class="drp-lnk">
          <router-link class="rl" :to="{ name: 'Alpha' }"> Hire </router-link>
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
          <div class="nav-dropdown">
            <div class="nav-col">
              <router-link :to="{ name: 'postjob' }">Post Job</router-link>
              <router-link :to="{ name: 'eprofile' }">Profile</router-link>
              <router-link :to="{ name: 'proposal' }">Proposals</router-link>
              <router-link :to="{ name: 'ecnt' }">Create Contract</router-link>
            </div>
            <div class="nav-col">
              <router-link :to="{ name: 'einvoice' }"
                >Create Invoice</router-link
              >
              <router-link :to="{ name: 'emsg' }">Messenger</router-link>
              <router-link :to="{ name: 'emtr' }">Work Monitoring</router-link>
              <router-link :to="{ name: 'MarketPlace' }"
                >By Project</router-link
              >
            </div>
          </div>
        </li>
        <li class="drp-lnk">
          <router-link class="rl" :to="{ name: 'MarketPlace' }">
            Marketplace</router-link
          >
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
          <div class="nav-dropdown">
            <div class="nav-col">
              <div
                class="forarm"
                v-for="mar in latestcategorys"
                :key="mar.id"
                @click="nextpage(mar, 'market')"
              >
                {{ mar.category }}<br />
              </div>
            </div>
          </div>
        </li>
        <li class="drp-lnk">
          <router-link class="rl" :to="{ name: 'element' }">
            Element's
          </router-link>
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
          <div class="nav-dropdown">
            <div class="nav-col">
              <div v-for="mar in lateststockcategory" :key="mar.id">
                <div
                  class="forarm"
                  v-for="mar in mar.pr_cat"
                  :key="mar.id"
                  @click="nextpage(mar, 'element')"
                >
                  {{ mar.category }}<br />
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="drp-lnk">
          <router-link class="rl" :to="{ name: 'workplace' }">
            Artboard
          </router-link>
        </li>
      </ul>
    </div>
    <div class="right--third-col">
      <ul>
        <li class="drp-lnk">
          <router-link class="rl" :to="{ name: 'LogIn' }"> LogIn </router-link>
        </li>
        <li class="drp-lnk">
          <router-link class="rl" :to="{ name: 'SignUp' }">
            SignUp
          </router-link>
        </li>
      </ul>
    </div>

    <div class="right" ref="menubtn">
      <ul>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
    <div class="model" ref="slidemenu">
      <div class="close-btn" @click="toggle">Close</div>
      <div class="model-menu">
        <div class="left--second-col-2">
          <ul>
            <li class="drp-lnk">
              <router-link class="rl" :to="{ name: 'Beta' }">
                Freelance
              </router-link>
              <i class="fa fa-chevron-down" aria-hidden="true"></i>
              <div class="nav-dropdown">
                <div class="nav-col">
                  <router-link :to="{ name: 'Beta' }">Search Work</router-link>
                  <router-link :to="{ name: 'fprofile' }">Profile</router-link>
                  <router-link :to="{ name: 'hjobs' }">Hired List</router-link>
                  <router-link :to="{ name: 'fcnt' }"
                    >Create Contract</router-link
                  >
                </div>
                <div class="nav-col">
                  <router-link :to="{ name: 'finvoice' }"
                    >Create Invoice</router-link
                  >
                  <router-link :to="{ name: 'fmsg' }">Messenger</router-link>
                  <router-link :to="{ name: 'fmtr' }"
                    >Work Monitoring</router-link
                  >
                  <router-link :to="{ name: 'crtpjt' }"
                    >Sell Your Project</router-link
                  >
                  <router-link :to="{ name: 'fmtr' }"
                    >Sell your creative elements</router-link
                  >
                  <router-link :to="{ name: 'fmtr' }"></router-link>
                </div>
              </div>
            </li>
            <li class="drp-lnk">
              <router-link class="rl" :to="{ name: 'Alpha' }">
                Hire
              </router-link>
              <i class="fa fa-chevron-down" aria-hidden="true"></i>
              <div class="nav-dropdown">
                <div class="nav-col">
                  <router-link :to="{ name: 'postjob' }">Post Job</router-link>
                  <router-link :to="{ name: 'eprofile' }">Profile</router-link>
                  <router-link :to="{ name: 'proposal' }"
                    >Proposals</router-link
                  >
                  <router-link :to="{ name: 'ecnt' }"
                    >Create Contract</router-link
                  >
                </div>
                <div class="nav-col">
                  <router-link :to="{ name: 'einvoice' }"
                    >Create Invoice</router-link
                  >
                  <router-link :to="{ name: 'emsg' }">Messenger</router-link>
                  <router-link :to="{ name: 'emtr' }"
                    >Work Monitoring</router-link
                  >
                  <router-link :to="{ name: 'MarketPlace' }"
                    >By Project</router-link
                  >
                </div>
              </div>
            </li>
            <li class="drp-lnk">
              <router-link class="rl" :to="{ name: 'MarketPlace' }">
                Marketplace
              </router-link>
            </li>
            <li class="drp-lnk">
              <router-link class="rl" :to="{ name: 'element' }">
                Element's
              </router-link>
            </li>
            <li class="drp-lnk">
              <router-link class="rl" :to="{ name: 'workplace' }">
                Artboard
              </router-link>
            </li>
          </ul>
        </div>
        <div class="right--third-col-2">
          <ul>
            <li class="drp-lnk">
              <router-link class="rl" :to="{ name: 'LogIn' }">
                LogIn
              </router-link>
            </li>
            <li class="drp-lnk">
              <router-link class="rl" :to="{ name: 'SignUp' }">
                SignUp
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "NavebarView",
  data() {
    return {
      ninja: [],
    };
  },
  mounted() {
    this.$refs.menubtn.addEventListener("click", this.toggle);
  },
  computed: {
    ...mapGetters(["latestcategorys"]),
    ...mapGetters(["lateststockcategory"]),
  },
  methods: {
    toggle() {
      let x = this.$refs.slidemenu;
      x.style.display === "none"
        ? (x.style.display = "flex")
        : (x.style.display = "none");
    },

    nextpage(category, platform) {
      if (platform === "market") {
        this.$router.push({
          name: "Categorywisemarket",
          query: { slug: category.slug, id: category.id },
        });
      } else {
        this.$router.push({
          name: "Categoryvisestock",
          query: { slug: category.slug, id: category.id },
        });
      }
    },
  },
};
</script>
<style scoped>
.nav {
  margin: 0;
  font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  font-size: 13px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  /* justify-content: space-between; */
  height: 50px;
}
.logo {
  padding-left: 30px;
  z-index: 10;
  position: relative;
  font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: grey;
  font-size: 1.2rem;
  /* background-color: #171818; */
}
.nav-dropdown a {
  cursor: pointer;
}
.left--second-col ul li {
  display: inline-block;
  padding: 0 15px;
  list-style: none;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  display: flex;
  align-items: center;
  justify-content: center;
}
.right--third-col,
li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right--third-col ul li {
  display: inline-block;
  padding: 0 15px;
  list-style: none;
}

.rl {
  text-decoration: none;
  color: inherit;
  position: relative;
}
.right {
  position: fixed;
  top: 0;
  left: 30px;
  z-index: 10;
  height: 50px;
  display: none;

  border-radius: 5%;
  width: 50px;
}
.nav-dropdown {
  position: absolute;
  min-width: 200px;
  min-height: 100px;
  background-color: #fff;
  display: none;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 11;
  justify-content: center;
}
.nav-dropdown a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.forarm {
  color: grey;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 13px;
}
.forarm:hover {
  background-color: #ddd;
}
.nav-dropdown a:hover {
  background-color: #ddd;
}
.drp-lnk:hover .nav-dropdown {
  display: flex;
}
.nav-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right ul li {
  width: 40px;
  height: 3px;
  background-color: #000000;
  margin: 8px;
  list-style: none;
  cursor: pointer;
}
.right ul li:nth-child(2) {
  width: 30px;
  height: 3px;
  background-color: grey;
  margin: 5px;
  list-style: none;
}
.model-menu {
  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
}
.model-menu ul li {
  list-style: none;
  justify-content: space-evenly;
  padding: 20px;
  color: white;
  font: bolder 1.5rem;
}
.model {
  display: none;
  z-index: 10;
  position: fixed;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 96vw;
  height: 96vh;
  right: 15px;
  top: 15px;
  background-color: #4d4c4cd3;
  border: 0.5rem outset rgb(5, 5, 5);
  outline: 0.5rem solid rgb(68, 68, 62);
  /* box-shadow: 0 0 0 2rem rgb(198, 198, 199); */
  /* border-radius: 12px; */
  font: bold 1rem sans-serif;
  /* margin: 2rem; */
  padding: 1rem;
  /* outline-offset: 0.5rem; */
  animation: fade 1s;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes trnsition {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
.close-btn {
  position: absolute;
  top: 10%;
  right: 10%;
  color: #ffffff;
  cursor: pointer;
}
.router-link-active {
  font-weight: bold;
}

@media (width<=1000px) {
  .right {
    display: block;
  }
  .left--second-col,
  .right--third-col {
    display: none;
  }
}
</style>
