<template>
  <div class="sidebar">
    <input type="checkbox" class="openSidebarMenu" id="openSidebarMenu" />
    <label for="openSidebarMenu" class="sidebarIconToggle">
      <div class="spinner diagonal part-1"></div>
      <div class="spinner horizontal"></div>
      <div class="spinner diagonal part-2"></div>
    </label>

    <div
      class="position-absolute top-0 end-0 btn-sm logout p-2"
      @click="logout()"
    >
      <!-- <div class="dot"></div>  -->
      <i class="fa fa-circle-o-notch p-t-2" aria-hidden="true"></i>
    </div>
    <!-- <div class="title" ><img src="../assets/wecoxer.png" width="170" height="170" /></div> -->
    <div class="menu-items" id="sidebarMenu">
      <router-link to="/dashboard" active-class="" exact class="side-btn">
        <div class="link-container">
          <i class="fa fa-th-large" aria-hidden="true"></i>Dashboard
        </div>
      </router-link>

      <router-link to="/postjob" active-class="" exact class="side-btn">
        <div class="link-container">
          <i class="fa fa-file-powerpoint-o" aria-hidden="true"></i>Post job
        </div>
      </router-link>

      <router-link to="/profile" active-class="active" exact class="side-btn">
        <div class="link-container">
          <i class="fa fa-user-o" aria-hidden="true"></i>Profile
        </div>
      </router-link>
      <router-link to="/messenger" active-class="active" exact class="side-btn">
        <div class="link-container">
          <i class="fa fa-comments-o" aria-hidden="true"></i>Messenger
        </div>
      </router-link>
      <router-link to="/proposal" active-class="active" exact class="side-btn">
        <div class="link-container">
          <i class="fa fa-file-text" aria-hidden="true"></i>Proposal
        </div>
      </router-link>

      <router-link to="/hired" active-class="active" exact class="side-btn">
        <div class="link-container">
          <i class="fa fa-user-secret" aria-hidden="true"></i>Hired
        </div>
      </router-link>

      <router-link
        to="/allcontract"
        active-class="active"
        exact
        class="side-btn"
      >
        <div class="link-container">
          <i class="fa fa-file-text" aria-hidden="true"></i>Contract
        </div>
      </router-link>

      <router-link
        to="/allinvoice"
        active-class="active"
        exact
        class="side-btn"
      >
        <div class="link-container">
          <i class="fa fa-file-pdf-o" aria-hidden="true"></i>Invoice
        </div>
      </router-link>
      <router-link
        to="/allmonitor"
        active-class="active"
        exact
        class="side-btn"
      >
        <div class="link-container">
          <i class="fa fa-desktop" aria-hidden="true"></i>Monitor
        </div>
      </router-link>
      <router-link
        to="/marketplace-order"
        active-class="active"
        exact
        class="side-btn"
      >
        <div class="link-container">
          <i class="fa fa-building-o" aria-hidden="true"></i>MarketOrder
        </div>
      </router-link>
      <router-link
        to="/marketplace"
        active-class="active"
        exact
        class="side-btn"
      >
        <div class="link-container">
          <i class="fa fa-shopping-cart" aria-hidden="true"></i>Marketplace
        </div>
      </router-link>
      <router-link to="/elements" active-class="" exact class="side-btn">
        <div class="link-container">
          <i class="fa fa-picture-o" aria-hidden="true"></i>Elements
        </div>
      </router-link>
      <router-link to="/workplace" active-class="" exact class="side-btn">
        <div class="link-container">
          <i class="fa fa-pencil-square-o" aria-hidden="true"></i>Workplace Beta
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "SidebarView",
  data() {
    return {};
  },
  methods: {
    async logout() {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("roll");
      this.$store.commit("removeRoll");
      localStorage.removeItem("userId");
      this.$store.commit("removeToken");
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.dot {
  height: 10px;
  width: 5px;
  background-color: black;
  z-index: 1;
  position: absolute;
  right: 19px;
  border-radius: 50%;
  top: 20px;
}
.router-link-exact-active {
  color: black;
  font-weight: bold;
}
.fa {
  padding-right: 5px;
  z-index: 2;
  position: absolute;
  right: 5px;
}
/* .logout {
  display: flex;
  align-items: center;
  justify-content: center;
} */
.dark-mode {
  background-color: black;
}

/* html,
body {
  overflow-x: hidden;
  height: 100%;
}
body {
  background: #fff;
  padding: 0;
  margin: 0;
} */

#testbuttonf {
  display: content;

  color: #000000;
}

.sidebar {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  box-shadow: none;
  background-color: rgb(255, 255, 255);
  position: fixed;
  height: 60px !important;
  overflow: hidden;
  z-index: 10;
  top: 0;
  box-shadow: 5px 5px 5px grey;
}
.side-btn {
  text-decoration: none;
  padding: 5px;

  display: block;
}
.menu-items {
  margin: 0 auto;
  text-decoration: none;
  /* display: table-cell; */
  vertical-align: middle;
  text-align: left;
  height: 100%;
  width: 100%;

  /* letter-spacing: 1.25px; */
}
#sidebarMenu {
  height: 100%;
  position: fixed;
  padding-top: 60px;
  top: 60px;
  left: 0;
  width: 150px;

  transform: translateX(-250px);
  transition: transform 250ms ease-in-out;
  color: #000000;
  background-color: rgb(255, 255, 255);
  box-shadow: 5px 5px 5px grey;
}
.menu-items {
  margin: 0;
  padding: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.menu-items div {
  list-style: none;
  color: grey;
  height: 20px;
  /* padding: 15px; */
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px;
}
.link-container {
  width: 150px;
  height: 30px;

  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
}
.link-container {
  color: #fff;

  cursor: pointer;
  text-decoration: none;
}
input[type="checkbox"]:checked ~ #sidebarMenu {
  transform: translateX(0);
}

input[type="checkbox"] {
  transition: all 0.3s;
  box-sizing: border-box;
  display: none;
}
.sidebarIconToggle {
  transition: all 0.3s;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  height: 100%;
  width: 100%;
  top: 22px;
  left: 15px;
  height: 22px;
  width: 22px;
}
.spinner {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #000000;
}
.horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}
.diagonal.part-1 {
  position: relative;
  transition: all 0.3s;
  box-sizing: border-box;
  float: left;
}
.diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}
input[type="checkbox"]:checked ~ .sidebarIconToggle > .horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 0;
}
input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(135deg);
  margin-top: 8px;
}
input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(-135deg);
  margin-top: -9px;
}
.title {
  visibility: hidden;
}
</style>
