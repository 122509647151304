import { createStore } from "vuex";
import axios from "axios";

const moduleA = {
  state: () => ({
    dashdata: [],
  }),
  mutations: { setdash: (state, dashdata) => (state.dashdata = dashdata) },
  actions: {
    async fetchdata({ commit }) {
      const respoce = await axios.get("/api/freelancer/dashboard/");
      commit("setdash", respoce.data);
    },
  },
  getters: {
    alldata: (state) => state.dashdata,
  },
};

const moduleB = {
  state: () => ({
    projects: [],
  }),
  mutations: { myprojects: (state, projects) => (state.projects = projects) },
  actions: {
    async fetchproject({ commit }) {
      const responce = await axios.get("api/marketplace/project/");
      commit("myprojects", responce.data);
    },
  },
  getters: {
    allprojects: (state) => state.projects,
  },
};
const moduleMarketplace = {
  state: () => ({
    latestproject: [],
    latestcategory: [],
    categoryproject: [],
    product: [],
    fetchlatestsprojuctn: Boolean,
    fetchlatestcategoryn: Boolean,
  }),
  mutations: {
    mylatestprojects: (state, latestproject) =>
      (state.latestproject = latestproject),
    mylatestcategory: (state, latestcategory) =>
      (state.latestcategory = latestcategory),
    categoryprojects: (state, categoryproject) =>
      (state.categoryproject = categoryproject),
    viewprojects: (state, product) => (state.product = product),
  },
  actions: {
    async fetchlatestproject({ commit }) {
      if (!this.fetchlatestsprojuctn) {
        const responce = await axios.get("api/marketplace/latest-products/");
        commit("mylatestprojects", responce.data);
        this.fetchlatestsprojuctn = true;
      }
    },
    async fetchlatestcategory({ commit }) {
      if (!this.fetchlatestcategoryn) {
        const responce = await axios.get("api/marketplace/latest-category/");
        commit("mylatestcategory", responce.data);
        this.fetchlatestcategoryn = true;
      }
    },
    async postcategoryproject({ commit }, category_slug) {
      const responce = await axios.get(
        "api/marketplace/projucts/" + category_slug
      );
      commit("categoryprojects", responce.data);
    },
    async fetchprojuctview({ commit }, slug) {
      const responce = await axios.get("api/marketplace/projucts/" + slug);
      commit("viewprojects", responce.data);
    },
  },
  getters: {
    latestprojects: (state) => state.latestproject,
    latestcategorys: (state) => state.latestcategory,
    categoryprojects: (state) => state.categoryproject,
    allprojectsview: (state) => state.product,
  },
};
const moduleStockImages = {
  state: () => ({
    lateststockimages: [],
    lateststockcategory: [],
    categorystockimages: [],
    stockimages: [],
    fetchlateststockImg: Boolean,

    fetchlateststockcat: Boolean,
  }),
  mutations: {
    mylateststockimages: (state, lateststockimages) =>
      (state.lateststockimages = lateststockimages),
    mylateststockcategory: (state, lateststockcategory) =>
      (state.lateststockcategory = lateststockcategory),
    categorystockimages: (state, categorystockimages) =>
      (state.categorystockimages = categorystockimages),
    viewstockimages: (state, stockimages) => (state.stockimages = stockimages),
  },
  actions: {
    async fetchlateststockimages({ commit }) {
      if (!this.fetchlateststockImg) {
        const responce = await axios.get("api/stockimage/latest-products/");
        commit("mylateststockimages", responce.data);
        this.fetchlateststockImg = true;
      }
    },
    async fetchlateststockcategory({ commit }) {
      if (!this.fetchlateststockcat) {
        const responce = await axios.get("api/stockimage/multiselect/");
        commit("mylateststockcategory", responce.data);
        this.fetchlateststockcat = true;
      }
    },
    async postcategorystock({ commit }, category_slug) {
      const responce = await axios.get(
        "api/stockimage/projucts/" + category_slug
      );
      commit("categorystockimages", responce.data);
    },
    async fetchstockview({ commit }, slug) {
      const responce = await axios.get("api/stockimage/projucts/" + slug);
      commit("viewstockimages", responce.data);
    },
  },
  getters: {
    lateststockimages: (state) => state.lateststockimages,
    lateststockcategory: (state) => state.lateststockcategory,
    categorystockimages: (state) => state.categorystockimages,
    allstockimages: (state) => state.stockimages,
  },
};

export default createStore({
  state: {
    // cart: {
    //   items: [],
    // },
    isAuthenticated: false,
    token: "",
    roll: "",
    isLoading: false,
  },
  mutations: {
    initializeStore(state) {
      // if (localStorage.getItem("cart")) {
      //   state.cart = JSON.parse(localStorage.getItem("cart"));
      // } else {
      //   localStorage.setItem("cart", JSON.stringify(state.cart));
      // }
      if (localStorage.getItem("token")) {
        state.token = localStorage.getItem("token");
        state.isAuthenticated = true;
      } else {
        state.token = "";
        state.isAuthenticated = false;
      }
      if (localStorage.getItem("roll")) {
        state.roll = localStorage.getItem("roll");
      } else {
        state.roll = "";
      }
    },
    // addToCart(state, item) {
    //   const exists = state.cart.items.filter(
    //     (i) => i.product.id === item.product.id
    //   );
    //   if (exists.length) {
    //     exists[0].quantity =
    //       parseInt(exists[0].quantity) + parseInt(item.quantity);
    //   } else {
    //     state.cart.items.push(item);
    //   }
    //   localStorage.setItem("cart", JSON.stringify(state.cart));
    // },
    setIsLoading(state, status) {
      state.isLoading = status;
    },
    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = true;
    },
    removeToken(state) {
      state.token = "";
      state.isAuthenticated = false;
    },
    setRoll(state, roll) {
      state.roll = roll;
    },
    removeRoll(state) {
      state.roll = "";
    },
    // clearCart(state) {
    //   state.cart = { items: [] };
    //   localStorage.setItem("cart", JSON.stringify(state.cart));
    // },
  },
  getters: {},
  actions: {},
  modules: {
    a: moduleA,
    b: moduleB,
    c: moduleMarketplace,
    d: moduleStockImages,
  },
});
