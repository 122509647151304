<template>
  <main>
    <div class="position-relative overflow-hidden p-3 p-md-3 text-center log">
      <div class="col-md-5 p-lg-5 mx-auto cnt">
        <slot></slot>
      </div>
      <!-- <div class="product-device shadow-sm d-none d-md-block"></div>
      <div
        class="product-device product-device-2 shadow-sm d-none d-md-block"
      ></div> -->
    </div>
  </main>
</template>
<script>
export default {
  name: "FormView",
};
</script>
<style scoped>
.log {
  background-color: #adbfce;

  min-height: 100vh;
}
a {
  cursor: pointer;
}
.cnt {
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 12px 8px 4px 2px #6d6d6d;
}
#testbutton {
  display: inline-block;
  font-weight: 400;
  border: 0.125rem solid transparent;
  position: relative;
  border-radius: 0.125rem;
  background-color: inherit;
  color: inherit;
  text-decoration: none;
  padding: 0.25rem 0.75rem;
  font-size: inherit;
  line-height: 1;
  cursor: pointer;
  background-color: #0067b8;
  color: #fff !important;
}
</style>
