<template>
  <div class="dashboard">
    <Sidebarf />
    <div class="content">
      <router-view />
    </div>
  </div>
</template>
<script>
import Sidebarf from "@/components/Sidebarf.vue";
export default {
  name: "BetaView",

  data() {
    return {};
  },

  components: {
    Sidebarf,
  },
  mounted() {},

  methods: {},
};
</script>

<style scoped>
.dashboard {
  display: grid;
  color: black;
  background-color: rgb(255, 255, 255);
}
ul,
li {
  padding: 0%;
  margin: 0%;
}

.content {
  background-color: white;

  margin: 60px 6px 6px 0px;
}
</style>
