<template>
  <Navbar />
  <section class="section-1">
    <div class="s-1-left">
      <h1>Hire or Get Hired</h1>
      <p>
        We provide a convenient and efficient way for freelancers and clients to
        connect, collaborate, and complete projects remotely. They offer a wide
        range of opportunities for individuals to showcase their skills and
        expertise while allowing businesses and individuals to find talented
        professionals for their specific needs.
      </p>
      <router-link class="btn-1" :to="{ name: 'SignUp' }"> SignUp </router-link>
      <router-link class="link" :to="{ name: 'LogIn' }">
        LearnMore >
      </router-link>
    </div>
    <div class="s-1-right">
      <!-- <img src="../assets/cover.png" alt="cover" class="cover" /> -->
    </div>
  </section>
  <section class="section-2">
    <div class="col">
      <img src="../assets/job.png" alt="job" class="icon" />
      <h1>Jobs</h1>
    </div>
    <div class="col">
      <img src="../assets/cart.png" alt="cart" class="icon" />
      <h1>Elements</h1>
    </div>
    <div class="col">
      <img src="../assets/marketplace.png" alt="marketplace" class="icon" />
      <h1>Marketplace</h1>
    </div>
    <div class="col">
      <img src="../assets/edit.png" alt="editor" class="icon" />
      <h1>PhotoEditor</h1>
    </div>
  </section>
  <section class="section-3">
    <div class="s-3-col">
      <img src="../assets/sect_3_1.jpg" alt="editor" class="img-s-3" />
      <p>
        You are not an employee of any company, but rather an independent
        contractor who can choose your own projects, hours, and rates. Freelance
        jobs can be found in various fields, such as writing, graphic design,
        web development, photography, and more. Freelancing can be a great way
        to earn money online, work from anywhere, and pursue your passions.
      </p>
      <router-link class="link" :to="{ name: 'LogIn' }">
        LearnMore >
      </router-link>
    </div>
    <div class="s-3-col">
      <img src="../assets/sect_3_2.jpg" alt="editor" class="img-s-3" />
      <p>
        Whether you are looking for graphic templates, stock videos,
        royalty-free music, web templates, or other digital assets, you can find
        them here. if you are interested in becoming an author. wecoxer is a
        great platform for anyone who loves creativity and wants to make
        something amazing.
      </p>
      <router-link class="link" :to="{ name: 'LogIn' }">
        LearnMore >
      </router-link>
    </div>
    <div class="s-3-col">
      <img src="../assets/sect_3_3.jpg" alt="editor" class="img-s-3" />
      <p>
        Our Marketplace that connects buyers and sellers of digital services
        online. It allows them to interact, communicate, and transact with each
        other through the internet. Our digital marketplace can facilitates the
        exchange of value between different parties.
      </p>
      <router-link class="link" :to="{ name: 'LogIn' }">
        LearnMore >
      </router-link>
    </div>
    <div class="s-3-col">
      <img src="../assets/sect_3_4.jpg" alt="editor" class="img-s-3" />
      <p>
        An Artboard is a software application that allows you to create, modify,
        and enhance digital images. Image editors can be used for various
        purposes, such as cropping, resizing, rotating, adjusting colors, adding
        filters, effects, text, shapes, stickers, and more.
      </p>
      <router-link class="link" :to="{ name: 'LogIn' }">
        LearnMore >
      </router-link>
    </div>
  </section>
  <section class="section-4">
    <div class="section-3-sub">
      <div class="carousel">
        <TransitionGroup name="fade">
          <li
            v-for="(cardd, index) in cardData"
            :key="cardd"
            class="cardv"
            :style="{
              transform: 'translateX(' + (index + indexChanger) * 100 + '%)',
            }"
            @click="pusen"
          >
            <h1>{{ cardd.h }}</h1>
            <p>{{ cardd.p }}</p>

            <router-link class="link" :to="{ name: 'LogIn' }">
              LearnMore >
            </router-link>
          </li>
        </TransitionGroup>
      </div>
    </div>
  </section>
  <FooterView />
</template>

<script>
import Navbar from "../components/Navbar.vue";
import FooterView from "../components/Footer.vue";
import { mapActions } from "vuex";
// import { metaInfo } from "vue-meta";
export default {
  name: "homeView",

  data() {
    return {
      alltags: [
        {
          type: "name",
          name: "keywords",
          type2: "content",
          content:
            "Freelancer,Upwork,Wecoxer,find work,part time,jobs,Marketplace,shuterstock",
        },
        {
          type: "name",
          name: "description",
          type2: "content",
          content:
            "Millions of Free Job,graphics resources. ✓ Vectors ✓ Stock Photos ✓ PSD ✓ Icons ✓ All that you need for your Creative Projects",
        },
        {
          type: "property",
          name: "og:title",
          type2: "content",
          content:
            "Millions of Free Jobs, Graphic Resources. ✓ Vectors ✓ Stock Photos ✓ PSD ✓ Icons ✓ All that you need for your Creative Projects",
        },
        {
          type: "property",
          name: "og:description",
          type2: "content",
          content:
            "Discover millions of copyright-free vectors, photos and PSD| Best Freelancing website for beginners|Find thousends of projuct in our market place",
        },

        {
          type: "property",
          name: "og:url",
          type2: "content",
          content: "https://wecoxer.com",
        },
        {
          type: "property",
          name: "og:image",
          type2: "href",
          content: "https://wecoxer.com/img/wecoxerr.dafe9b6c.png",
        },
        {
          type: "property",
          name: "og:image:alt",
          type2: "content",
          content:
            "Find Free Vectors, Stock Photos and PSD, freelancer,marketplace",
        },

        {
          type: "name",
          name: "twitter:title",
          type2: "content",
          content: "Wecoxer | Graphic Resources for everyone",
        },
        {
          type: "name",
          name: "twitter:image:src",
          type2: "href",
          content: "https://wecoxer.com/img/wecoxerr.dafe9b6c.png",
        },
        {
          type: "name",
          name: "twitter:url",
          type2: "content",
          content: "https://wecoxer.com",
        },
        {
          type: "name",
          name: "twitter:description",
          type2: "content",
          content:
            "Find freelance works,jobs | Discover millions of copyright-free vectors, photos and PSD",
        },
      ],
      cardData: [
        {
          h: "User Registration and Profiles",
          p: "Freelancers and clients can create accounts and provide relevant information about their skills, experience, and preferencesThis allows users to build their professional profiles and showcase their expertise.",
        },
        {
          h: "Job Posting and Bidding",
          p: "Clients can post projects or jobs they need to be completed and specify their requirements, deadlines, and budget.Freelancers can browse through available jobs and submit proposals or bids, outlining their approach,timeline, and pricing for the project.",
        },
        {
          h: "Messaging and Communication",
          p: "A messaging system enables direct communication between freelancers and clients. It allows them to discuss project details, negotiate terms,  and address any questions or concerns.",
        },
        {
          h: "Escrow and Payment System(Coming Soon)",
          p: "A secure payment gateway or escrow system is often implemented to handle financial transactions. It ensures that freelancers receive payment for their work and clients have recourse if any issues arise.",
        },
        {
          h: "Reviews and Ratings",
          p: " Clients can provide feedback and rate freelancers based on their performance and the quality of work delivered. These reviews and ratings help build trust and credibility within the freelancing community.",
        },
        {
          h: "Project Management Tools",
          p: "We offer project management features such as task tracking and milestone management.These tools help streamline  collaboration and ensure smooth project execution.",
        },
        {
          h: "Dispute Resolution",
          p: "To avoid conflicts or disagreements,Both party can create contract and this  dispute  resolution mechanisms to mediate between freelancers and clients and find a fair resolution.",
        },
        {
          h: "upcoming  Features",
          p: " There may be other features such as portfolio showcases, skill testing, job recommendations, and advanced search filters to enhance the freelancing experience.",
        },
      ],
      indexChanger: 0,
      flowUp: true,
      isSelected: 0,
      pauser: false,
      interval: "",
    };
  },
  beforeCreate() {},
  mounted() {
    this.metaTag();
    document.title = "Home|WecoXer";
    this.autoCarousel();
    if (this.$store.getters.lateststockcategory.length < 1) {
      this.fetchlateststockcategory();
      this.fetchlatestcategory();
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // eslint-disable-next-line no-undef
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-0FNTT0793G");
  },
  unmounted() {
    this.pauser = true;
    clearInterval(this.interval);
    this.removeTag();
  },
  // beforedistroy() {
  //   this.removeTag();
  // },

  methods: {
    ...mapActions(["fetchlateststockcategory"]),
    ...mapActions(["fetchlatestcategory"]),
    metaTag() {
      for (let i = 0; i < this.alltags.length; i++) {
        let meta = document.createElement("meta");
        meta.setAttribute(this.alltags[i].type, this.alltags[i].name);
        meta.setAttribute(this.alltags[i].type2, this.alltags[i].content);
        meta.setAttribute("class", "changable");
        document.head.appendChild(meta);
      }
    },
    async removeTag() {
      document.querySelectorAll(".changable").forEach((element) => {
        element.remove();
      });
    },
    autoCarousel(index = undefined) {
      if (index === undefined) {
        this.interval = setInterval(() => {
          if (!this.pauser) {
            if (
              this.indexChanger < this.cardData.length &&
              this.indexChanger >= -14 &&
              this.flowUp
            ) {
              this.indexChanger--;
            } else {
              if (this.indexChanger <= 0) {
                this.flowUp = false;
                this.indexChanger++;
              } else {
                this.flowUp = true;
              }
            }
          }
        }, 5000);
      }
    },
    pausen() {
      this.pauser = !this.pauser;
    },
  },
  components: { Navbar, FooterView },
};
</script>

<style Scoped>
.section-1 {
  /* background-color: #c9e3fa; */
  min-height: 80vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  /* transform: scaleX(-1); */
  background-image: url("../assets/bg-fnt.jpg");
  background-size: cover;
}
.btn-1 {
  display: inline-block;
  font-weight: 400;
  border: 0.125rem solid transparent;
  position: relative;
  border-radius: 0.125rem;
  background-color: inherit;
  color: inherit;
  text-decoration: none;
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  background-color: #0067b8;
  color: #fff !important;
}
.s-1-left {
  width: 50vw;
  height: 100vh;
  padding-left: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.s-1-left h1 {
  font-size: 2.3125rem;
  margin-bottom: 0.5rem;
}
.s-1-left p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1.25rem;
  text-align: justify;
}
.section-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40vh;

  justify-content: space-evenly;
}
.col {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  justify-content: center;
}
.icon {
  width: 20%;
}
.section-3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 50vh;
  padding: 1rem;
  justify-content: space-evenly;
  margin: 2rem;
}
.s-3-col {
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  justify-content: start;
  align-self: stretch;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1.25rem;
}
.s-3-col p {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  width: 20vw;
}
.img-s-3 {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 90%;
  height: 200px;
  flex-shrink: 0;
}
.s-1-right {
  width: 50vw;
  height: 100vh;
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cover {
  width: 100%;
  height: 80vh;
}
.section-4 {
  height: 100vh;
  background-image: url("../assets/sect_4_bg.jpg");
  /* background-color: #adbfce; */
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.section-3-sub {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0%;
  left: 0%;

  overflow: hidden;
  z-index: 1;
}
.carousel {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  min-height: 40vh;
  min-width: 100vw;
  row-gap: 10px;
}
.carousel li {
  margin: 0;
  list-style-position: initial;
}
.switches {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 25vw;
  cursor: pointer;
}
.cardv {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 100vw;

  padding: 1%;
  /* box-shadow: 5px 5px 5px black; */
  transition: all 1s linear;
  background-color: rgba(0, 0, 0, 0.403);
}
.cardv h1 {
  color: white;
  font-size: 2.3125rem;
  margin-bottom: 0.5rem;
  text-align: justify;
}
.cardv p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1.25rem;
  text-align: justify;
  color: white;
}
@media (width<1000px) {
  .section-1,
  .section-2,
  .section-3 {
    display: flex;
    flex-direction: column;
  }
  .s-3-col p {
    width: auto;
  }
  .img-s-3 {
    height: auto;
  }
  .s-1-left {
    width: 100vw;
    padding: 20px;
  }
  .s-1-right {
    display: none;
  }
  .cardv h1,
  .s-1-left h1,
  .col h1 h1 {
    font-size: 1.2rem;
  }
  p,
  a,
  .s-1-left p,
  .cardv p {
    font-size: 16px;
  }
}
</style>
