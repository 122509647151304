<template>
  <div>
    <div
      class="is-loadig-bar text-center"
      :class="{ 'is-loading': $store.state.isLoading }"
    >
      <div class="lds-dual-ring"></div>
    </div>
    <router-view />
  </div>
  <div class="up-btn" ref="upbtn" @click="up">Back To Top</div>
</template>
<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      showMobileMenu: false,
      // cart: {
      //   items: [],
      // },
      value: [0, 1],
      pausen: false,
      alltags: [
        {
          type: "http-equiv",
          name: "Content-Security-Policy",
          type2: "value",
          content: "default-src 'self' cdn.wecoxer.com; img-src*,",
        },

        {
          type: "property",
          name: "og:type",
          type2: "content",
          content: "article",
        },

        {
          type: "property",
          name: "og:site_name",
          type2: "content",
          content: "Wecoxer",
        },
        {
          type: "property",
          name: "fb:admins",
          type2: "content",
          content: "110306301803952",
        },
        {
          type: "name",
          name: "twitter:card",
          type2: "content",
          content: "summary_large_image",
        },
        {
          type: "name",
          name: "twitter:site",
          type2: "content",
          content: "@wecoxer",
        },
        {
          type: "name",
          name: "twitter:creator",
          type2: "content",
          content: "@BhattPradyumnan",
        },
      ],
    };
  },

  mounted() {
    this.preLoader();
    window.screen.width >= 1024 && window.screen.height >= 768
      ? (window.addEventListener("scroll", this.scroll),
        window.addEventListener("click", this.pause))
      : "";
    // let x = document.createElement("META");
    // x.setAttribute("http-equiv", "Content-Security-Policy");
    // x.setAttribute("value", "default-src 'self'; img-src*");

    // document.head.appendChild(x);
    this.metaTag();
    // this.cart = this.$store.state.cart;
    if (this.$store.getters.lateststockcategory.length < 1) {
      this.fetchlateststockcategory();
    }
  },
  unmounted() {
    window.screen.width >= 1024
      ? (window.removeEventListener("scroll", this.scroll),
        window.removeEventListener("click", this.pause))
      : "";
  },

  beforeCreate() {
    this.$store.commit("setIsLoading", true);
    this.$store.commit("initializeStore");
    const token = this.$store.state.token;
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios.defaults.headers.common["Content-Type"] = "application/json";
    } else {
      axios.defaults.headers.common["Authorization"] = "";
      axios.defaults.headers.common["Content-Type"] = "application/json";
    }
  },
  methods: {
    ...mapActions(["fetchlateststockcategory"]),
    metaTag() {
      for (let i = 0; i < this.alltags.length; i++) {
        let meta = document.createElement("meta");
        meta.setAttribute(this.alltags[i].type, this.alltags[i].name);
        meta.setAttribute(this.alltags[i].type2, this.alltags[i].content);
        // meta.name = this.alltags[i].name;
        // meta.content = this.alltags[i].content;
        document.head.appendChild(meta);
      }
    },
    scroll() {
      this.topbtn();
      this.value = [...this.value, Math.ceil(window.scrollY)];
      let vl = this.value.length;
      if (this.pausen === false) {
        this.value[vl - 1] > this.value[vl - 2]
          ? setInterval(
              window.scrollTo(0, this.value[vl - 1] + 4, {
                behaviour: "smooth",
              }),
              8
            )
          : setInterval(
              window.scrollTo(0, this.value[vl - 1] - 4, {
                behaviour: "smooth",
              }),
              1
            );
      }
    },
    pause() {
      this.pausen ? (this.pausen = false) : (this.pausen = true);
    },
    topbtn() {
      let btn = this.$refs.upbtn;

      if (
        document.body.scrollTop > 10 ||
        document.documentElement.scrollTop > 20
      ) {
        btn.style.display = "flex";
        btn.style.transition = "all 1s linear";
      } else {
        btn.style.display = "none";
      }
    },
    up() {
      let height = window.scrollY;

      setTimeout(() => {
        if (height > 0) {
          height--;
          window.scrollTo(0, height);
        } else {
          clearTimeout();
        }
      }, 200);
      //
    },
    preLoader() {
      setTimeout(() => {
        this.$store.commit("setIsLoading", false);
      }, 1000);
    },
  },

  computed: {
    // cartTotalLength() {
    //   let totalLength = 0;
    //   for (let i = 0; i < this.cart.items.length; i++) {
    //     totalLength += this.cart.items[i].quantity;
    //   }
    //   return totalLength;
    // },
  },
};
</script>

<style>
#app {
  font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

* {
  box-sizing: border-box;
  font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  margin: 0;
  padding: 0;
}

@import "../src/fonts/fontawesome.min.css";
@import "../src/fonts/bootstrap-icons.css";

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 100vh;
  vertical-align: bottom;
}
.lds-dual-ring:after {
  content: "";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;

  border-radius: 50%;
  border: 6px solid#ccc;
  border-color: rgb(0, 0, 0) transparent #6b6b6b transparent;
  animation: ids-dual-ring 1.2s linear infinite;
}
@keyframes ids-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.is-loadig-bar {
  position: fixed;
  left: 0px;
  top: 50%;
  width: 100%;
  height: 0;
  z-index: 9999;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.is-loading {
  position: fixed;
  left: 0px;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.up-btn {
  position: fixed;
  bottom: 1%;
  right: 1%;

  width: auto;
  height: 40px;
  color: black;
  display: none;
  align-items: center;
  transition: all 3 linear;
  justify-content: center;

  background-color: #d9d9d9;

  font-weight: 600;
  border: 0.125rem solid transparent;

  border-radius: 0.125rem;

  color: inherit;
  text-decoration: none;
  padding: 0.625rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
}
.btn-1 {
  display: inline-block;
  font-weight: 400;
  border: 0.125rem solid transparent;
  position: relative;
  border-radius: 0.125rem;
  background-color: inherit;
  color: inherit;
  text-decoration: none;
  padding: 0.25rem 0.75rem;
  font-size: inherit;
  line-height: 1;
  cursor: pointer;
  background-color: #0067b8;
  color: #fff !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
