<template>
  <FormView>
    <form @submit.prevent="submitForm" class="p-4">
      <!-- Email input -->
      <div class="form-outline mb-4">
        <label for="username" class="d-flex justify-content-start">
          Email</label
        >
        <input
          type="email"
          placeholder="email"
          id="form1Example13"
          class="form-control form-control-lg"
          v-model="username"
          @keyup="validateInput(username)"
          required
        />
      </div>

      <!-- Password input -->
      <div class="form-outline mb-4">
        <label for="password" class="d-flex justify-content-start">
          Password</label
        >
        <input
          type="password"
          placeholder="password"
          id="form1Example23"
          class="form-control form-control-lg"
          v-model="password"
          @keyup="validateInput(password)"
          title="Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters"
          minlength="8"
          required
        />
      </div>

      <div class="d-flex justify-content-end align-items-center mb-4">
        <!-- Checkbox -->

        <a @click="forgotpassword">Forgot password?</a>
      </div>
      <div class="notification is-danger text-danger" v-if="errors.length">
        <p v-for="error in errors" :key="error">{{ error }}</p>
      </div>
      <!-- Submit button -->
      <button type="submit" id="testbutton">Login</button>
    </form>
  </FormView>
</template>

<script>
import axios from "axios";
import FormView from "@/components/Form.vue";

export default {
  name: "LogIn",
  components: {
    FormView,
  },
  data() {
    return {
      username: "",
      password: "",
      errors: [],
    };
  },
  mounted() {
    document.title = "Log In| Wecoxer";
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // eslint-disable-next-line no-undef
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-0FNTT0793G");
  },
  computed: {
    // console: () => console,
  },
  methods: {
    validateInput(value) {
      return (this.errors[0] =
        value === "" ? "The Input field is required" : "");
    },
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      const formData = {
        username: this.username,
        password: this.password,
      };
      await axios
        .post("users/api/login/", formData)
        .then((response) => {
          const token = response.data.token;

          this.$store.commit("setToken", token);
          axios.defaults.headers.common["Authorization"] = "Token " + token;

          localStorage.setItem("token", token);
          const topath = this.$route.query.to || "/roll";
          this.$router.push(topath);
        })
        .catch((error) => {
          this.$store.commit("setIsLoading", false);
          console.log(error);
          if (error.responce) {
            for (const property in error.responce.data) {
              this.errors.push(`${property}:${error.responce.data[property]}`);
            }
          } else if (error.message) {
            this.errors.push("Login Id or Password incorrect");
            this.errors.push(error.message);
          }
        });
    },
    forgotpassword() {
      this.$store.commit("setIsLoading", true);
      this.$router.push({ name: "PasswordReset" });
    },
  },
};
</script>

<style scoped>
#testbutton {
  display: inline-block;
  font-weight: 400;
  border: 0.125rem solid transparent;
  position: relative;
  border-radius: 0.125rem;
  background-color: inherit;
  color: inherit;
  text-decoration: none;
  padding: 0.25rem 0.75rem;
  font-size: inherit;
  line-height: 1;
  cursor: pointer;
  background-color: #0067b8;
  color: #fff !important;
}
</style>
