<template>
  <div class="ashboard">
    <Sidebar />
    <div class="content">
      <router-view />
    </div>
  </div>
</template>
<script>
import Sidebar from "../components/Sidebar.vue";
export default {
  name: "AlphaView",
  data() {
    return {};
  },

  components: {
    Sidebar,
  },
  mounted() {},

  methods: {},
};
</script>
<style scoped>
::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background: #ffffff;
}
::-webkit-scrollbar-thumb {
  background: grey;
}

.dashboard {
  display: grid;
  color: black;
  background-color: rgb(255, 255, 255);
}
ul,
li {
  padding: 0%;
  margin: 0%;
}

.content {
  /* padding-left: 150px; */
  background-color: white;
  /* border-radius: 10px; */
  margin: 60px 6px 6px 0px;
}
</style>
